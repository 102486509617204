/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */
// Mock services must match their real counterparts but often ignore parameters and have hard-coded responses

import { NgZone, PLATFORM_ID } from '@angular/core';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import auth from 'firebase/compat/app';
import { of as observableOf, from as observableFrom, Observable, BehaviorSubject } from 'rxjs';

import { environment } from '@environment';
import 'firebase/auth';

interface IMockUser {
  authState: auth.User;
  password: string;
}

interface IExtendedUserInfo extends Partial<auth.UserInfo> {
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
}

const users: {
  [provider: string]: {
    [email: string]: IMockUser;
  };
} = {};

const createNewUser = (userInfo: IExtendedUserInfo, type: string) => {
  const provider = type;
  if (!users[provider]) {
    users[provider] = {};
  }

  const getNext = (attr) => {
    const nextCount = Object.keys(users[provider]).length + 1;
    return attr + type + nextCount;
  };

  const encodeJWT = (header, data) => `${btoa(JSON.stringify(header))}.${btoa(JSON.stringify(data))}`;

  const uid = getNext('uid');
  let token;
  const newUser: IMockUser = {
    authState: {
      uid,
      providerId: type || 'Firebase',
      email: userInfo.email,
      getIdToken: () => Promise.resolve(token),
      updateProfile: (attrs) => {
        Object.assign(users[provider][userInfo.email].authState, attrs);
        return Promise.resolve();
      },
      sendEmailVerification: () => Promise.resolve(),
    } as auth.User,
    password: userInfo.password,
  };

  // Token and authState should only have a name if we have one
  if (userInfo.lastName) {
    const name = `${userInfo.firstName} ${userInfo.lastName}`;
    newUser.authState.displayName = name;
    token = encodeJWT({ alg: 'RS256', typ: 'JWT' }, { user_id: uid, email: userInfo.email, name });
  } else {
    token = encodeJWT({ alg: 'RS256', typ: 'JWT' }, { user_id: uid, email: userInfo.email });
  }

  users[provider][userInfo.email] = newUser;
};

export const setupUsers = (type: string, newUsers: IExtendedUserInfo[]) => {
  newUsers.forEach((user) => {
    createNewUser(user, type);
  });
};

const socialUser = (provider: string) => {
  // TODO: possibly handle multiple logged in users for social provider
  const firstOfType = Object.keys(users[provider])[0];
  return users[provider][firstOfType];
};

export class MockFirebaseAuth {
  user$ = new BehaviorSubject<auth.User>(null);
  get authState(): Observable<auth.User> {
    return this.user$.asObservable();
  }

  get idToken(): Observable<string> {
    return this.currentUser ? observableFrom(this.currentUser.getIdToken()) : observableOf(null);
  }

  get user(): Observable<auth.User> {
    return this.authState;
  }

  get currentUser(): auth.User {
    const user = this.user$.value;
    if (user) {
      user.updateProfile = () => Promise.resolve();
    }
    return user;
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<auth.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      const user = users.Password[email];
      if (user && password === user.password) {
        resolve({ user: user.authState, credential: null });
        setTimeout(() => {
          this.user$.next(user.authState);
        }, 0);
      } else {
        reject(new Error('incorrect creds'));
      }
    });
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<auth.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      if (!email || !password) {
        reject(new Error('incorrect creds'));
        this.user$.next(null);
      }

      createNewUser({ email, password }, 'Password');
      const cred = { user: users.Password[email].authState, credential: null };
      resolve(cred);
      this.signInWithEmailAndPassword(email, password);
    });
  }

  signInWithPopup(provider: auth.auth.AuthProvider): Promise<auth.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      const providerKey = provider.providerId.match('google') ? 'Google' : 'Facebook';
      if (socialUser(providerKey)) {
        const user = socialUser(providerKey).authState;
        resolve({
          user,
          credential: null,
          // todo:: BAD! Quick hack for allowing additionalUserInfo in mock firebase class.
          additionalUserInfo: {
            profile: {
              given_name: 'google_given',
              family_name: 'google_family',
              first_name: 'fb_first',
              last_name: 'fb_last',
            },
            isNewUser: false,
            providerId: '',
          },
        });
        setTimeout(() => {
          this.user$.next(user);
        }, 0);
      } else {
        reject(new Error('incorrect creds'));
      }
    });
  }

  signOut(): Promise<null> {
    return new Promise((resolve) => {
      resolve(null);
      setTimeout(() => {
        this.user$.next(null);
      }, 0);
    });
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return Promise.resolve();
  }
}

export class MockAngularFireAuth {
  auth: MockFirebaseAuth;
  signInWithPopup: (provider: auth.auth.AuthProvider) => Promise<auth.auth.UserCredential>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<auth.auth.UserCredential>;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<auth.auth.UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  signOut: () => Promise<null>;
  currentUser: Promise<Partial<auth.User>>;
  authState: Observable<auth.User>;
  idToken: Observable<string>;
  user: Observable<auth.User>;
  setupUsers = setupUsers;

  constructor() {
    this.auth = new MockFirebaseAuth();
    this.signInWithPopup = this.auth.signInWithPopup;
    this.signInWithEmailAndPassword = this.auth.signInWithEmailAndPassword;
    this.createUserWithEmailAndPassword = this.auth.createUserWithEmailAndPassword;
    this.sendPasswordResetEmail = this.auth.sendPasswordResetEmail;
    this.currentUser = new Promise((resolve) => {
      resolve({
        updateProfile: () =>
          new Promise((done) => {
            done();
          }),
      });
    });
    this.signOut = this.auth.signOut;
    this.authState = this.auth.authState;
    this.idToken = this.auth.idToken;
    this.user = this.auth.user;
  }
}

export const angularFireAuthFactory = (platformId: object, zone: NgZone): AngularFireAuth | MockAngularFireAuth => {
  if (environment.e2e) {
    if (environment.mockUsers) {
      Object.keys(environment.mockUsers).forEach((type) => {
        setupUsers(type, environment.mockUsers[type]);
      });
    }
    return new MockAngularFireAuth();
  }
  return new AngularFireAuth(
    environment.firebase,
    '',
    platformId,
    zone,
    new ɵAngularFireSchedulers(zone),
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  );
};

export const fakeAngularFireAuth = {
  provide: AngularFireAuth,
  useFactory: angularFireAuthFactory,
  deps: [PLATFORM_ID, NgZone],
};
