import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { AutoResolveTask } from '@shared/classes/auto-resolve/auto-resolve';
import { STAGE_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { Payment } from '@shared/models/payment';
import { Task } from '@shared/models/task';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { ProcessService } from '@shared/services/process/process.service';
import { WindowService } from '@shared/services/window/window.service';

const DELAY_ALLOWED_ENVS = ['develop', 'test', 'train', 'uat'];
const MAX_PAYMENT_RETRIES = 4;
const PAYMENT_ACTIONS = {
  REDIRECT: 'REDIRECT',
  CONTINUE_NO_REDIRECT: 'CONTINUE_NO_REDIRECT',
};

@Component({
  selector: 'uc-online-resolve-checkout-session',
  templateUrl: './online-resolve-checkout-session.component.html',
  styleUrls: ['./online-resolve-checkout-session.component.scss'],
})
export class OnlineResolveCheckoutSessionComponent extends AutoResolveTask implements OnInit {
  @Input() task: Task;
  @Input() process: string;
  @Input() applicationYear: string;
  @Input() courseCode: string;
  @Input() occurrence: string;

  log: Logger;
  strings = strings.components.tasks.onlineResolveCheckoutSession.awaitingCheckoutSession;
  ws: WindowService;

  constructor(
    logService: LoggingService,
    processService: ProcessService,
    enrolmentService: EnrolmentService,
    paymentService: PaymentService,
    windowService: WindowService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessageService: FlashMessageService,
  ) {
    super(processService, enrolmentService, paymentService, windowService, logService);
    this.log = logService.createLogger(this);
    this.ws = windowService;
  }

  get checkoutRedirectPath() {
    return internalUrls.onlineProcessPageStage(this.process, this.applicationYear, STAGE_NAMES.CHECKOUT);
  }

  get isTestEnvironment() {
    return DELAY_ALLOWED_ENVS.includes(environment.envName);
  }

  get shouldDelayStripeLinkForTesting() {
    return this.isTestEnvironment && localStorage.getItem('STRIPE_PAYMENT_DELAY') === 'TRUE';
  }

  ngOnInit() {
    // Check if the user is in a test environment and if they want to delay the Stripe link
    // for testing purposes
    if (this.shouldDelayStripeLinkForTesting) {
      this.startDelayedPaymentFlow();
    } else {
      this.startPaymentFlow();
    }
  }

  /**
   * Same as startPaymentFlow but delays the payment link for a given amount of time
   * to simulate a failure scenario.
   *
   * This feature will be disabled in production.
   */
  startDelayedPaymentFlow() {
    // Ensure this is not called in production
    if (!this.isTestEnvironment) {
      this.log.error('startDelayedPaymentFlow should not be called in production');
      return;
    }

    // Get the delay from the user
    const delay = prompt('Enter payment delay in seconds (for setting up a failure scenario)', '40');
    const delayInt = parseInt(delay) * 1000;

    setTimeout(() => this.startPaymentFlow(), delayInt);
  }

  /**
   * Start the payment flow by getting the payment link from the server
   * and redirecting the user to the payment gateway.
   */
  startPaymentFlow() {
    this.paymentService.getPaymentLink(this.process, this.applicationYear, this.courseCode, this.occurrence).subscribe({
      next: (res) => this.handlePaymentLinkResult(res),
      error: (err) => this.paymentError(err.message),
    });
  }

  /**
   * Redirect user to payment gateway URL.
   * @param url The URL to redirect to.
   */
  redirectToPaymentGateway(url) {
    this.ws.nativeWindow.location.href = url;
  }

  /**
   * Get the current queryParams but keeps track of the number of payment retries
   * we've done
   *
   * @returns {retries: number, ...queryParams}
   */
  async getQueryParamsWithRetries() {
    let retries = 1;
    const queryParams = await firstValueFrom(this.route.queryParams);
    const hasRetriesParam = Object.keys(queryParams).includes('retries');

    if (hasRetriesParam) {
      retries = parseInt(queryParams.retries) + 1;
    }

    return {
      ...queryParams,
      retries,
    };
  }

  /**
   * 'Bypass' the payment by re-redirecting to the checkout page. If the payment
   * has been corrected - the existing logic will pick this up and redirect the user
   * to the correct place.
   *
   * If we have exceeded the max retries, we will show an error message and redirect to the homepage.
   */
  async bypassPayment() {
    const queryParams = await this.getQueryParamsWithRetries();

    if (queryParams.retries >= MAX_PAYMENT_RETRIES) {
      return await this.paymentError('Max retries exceeded when awaiting payment URL');
    }

    this.router.navigate(this.checkoutRedirectPath, { queryParams });
  }

  /**
   * Handle payment error by showing a flash message and redirecting to the homepage.
   * @param error The error message to display.
   */
  async paymentError(error: string) {
    const queryParams = await this.getQueryParamsWithRetries();

    // If we have recieved an error after the first retry, this could be because
    // the API is returning a 404 since getting a payment link is no longer valid (because they're enrolled)
    if (queryParams.retries && queryParams.retries < MAX_PAYMENT_RETRIES) {
      this.flashMessageService.pushSubtle(this.strings.paymentError.tryAgainPossiblePaymentAttempted);
    } else {
      this.flashMessageService.pushError(this.strings.paymentError.tryAgain);
    }

    this.log.error(error);
    return await this.router.navigate(['/']);
  }

  /**
   * Handle the payment link result by redirecting to the payment gateway or bypassing the payment.
   *
   * Two expected actions from backend:
   * - REDIRECT: Redirect to the payment link
   * - CONTINUE_NO_REDIRECT: Bypass the payment and continue to the next step
   *
   * If the action is not recognized, redirect to the payment link as a fallback.
   *
   * @param payment The payment object received from the server.
   */
  handlePaymentLinkResult(payment: Payment) {
    const defaultAction = PAYMENT_ACTIONS.REDIRECT;
    const action = payment.nextAction || defaultAction;

    switch (action) {
      /**
       * Redirect to the payment link. This indicates that
       * the payment is required and the user should be redirected.
       */
      case PAYMENT_ACTIONS.REDIRECT:
        return this.redirectToPaymentGateway(payment.link);
      /**
       * The payment is not required - the user may have
       * already paid for this course.
       */
      case PAYMENT_ACTIONS.CONTINUE_NO_REDIRECT:
        return this.bypassPayment();
      /**
       * Fallback to old behavior - redirect to the payment link.
       */
      default:
        this.redirectToPaymentGateway(payment.link);
        this.log.error('Unsupported action received from server. Redirecting to payment link as a fallback');
        break;
    }
  }
}
