import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { ENV_NAMES, PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { REFDATA_TYPES, ReferenceData } from '@shared/models/reference-data';
import { ApplicationService } from '@shared/services/application/application.service';
import { DataService } from '@shared/services/data-service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { mockData as mockReferenceData } from '@shared/services/reference-data/reference-data.mock';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

export interface CourseSearchOptions {
  includeAll?: boolean;
}

const SHORT_COURSE = 'Short course';
const MICRO_CRED = 'Micro-credential';

const COURSE_CATEGORIES = [SHORT_COURSE, MICRO_CRED];

@Injectable()
export class OnlineCourseService {
  private serviceUrl = `${environment.apiRoot}/online-course`;
  private log: Logger;
  public onlineCourseType$ = new BehaviorSubject<string>(null);
  public onlineCourseCode$ = new BehaviorSubject<string>(null);
  public onlineCourseOccur$ = new BehaviorSubject<string>(null);
  public isResumingApplication$ = new BehaviorSubject<boolean>(false);
  // Spike: add a temp place to hold process query parameters
  public programme$ = new BehaviorSubject<string>(null);
  public onlineCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(null);
  public onlineCourseIsFirstTimeToThisCourseType$ = new BehaviorSubject<boolean>(false);
  public onlineCourseIsMCEnrolledBefore$ = new BehaviorSubject<boolean>(false);
  public onlineCourseEnrolmentContextIsBuilt$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dataService: DataService,
    private refDataService: ReferenceDataService,
    private enrolmentService: EnrolmentService,
    private applicationService: ApplicationService,
    private router: Router,
    private loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get onlineCourseType(): string {
    return this.onlineCourseType$.value;
  }

  set onlineCourseType(value) {
    this.onlineCourseType$.next(value);
  }

  get onlineCourseCode(): string {
    return this.onlineCourseCode$.value;
  }

  set onlineCourseCode(value) {
    this.onlineCourseCode$.next(value);
  }

  get isResumingApplication(): boolean {
    return this.isResumingApplication$.value;
  }

  get onlineCourseOccur(): string {
    return this.onlineCourseOccur$.value;
  }

  set onlineCourseOccur(value) {
    this.onlineCourseOccur$.next(value);
  }

  get programme(): string {
    return this.programme$.value;
  }

  set programme(value) {
    this.programme$.next(value);
  }

  get onlineCourseSelectedCourse(): ReferenceData {
    return this.onlineCourseSelectedCourse$.value;
  }

  set onlineCourseSelectedCourse(value) {
    this.onlineCourseSelectedCourse$.next(value);
  }

  get onlineCourseIsFirstTimeToThisCourseType(): boolean {
    return this.onlineCourseIsFirstTimeToThisCourseType$.value;
  }

  set onlineCourseIsFirstTimeToThisCourseType(value) {
    this.onlineCourseIsFirstTimeToThisCourseType$.next(value);
  }

  get onlineCourseEnrolmentContextIsBuilt(): boolean {
    return this.onlineCourseEnrolmentContextIsBuilt$.value;
  }

  set onlineCourseEnrolmentContextIsBuilt(value) {
    this.onlineCourseEnrolmentContextIsBuilt$.next(value);
  }

  get onlineCourseIsMCEnrolledBefore(): boolean {
    return this.onlineCourseIsMCEnrolledBefore$.value;
  }

  set onlineCourseIsMCEnrolledBefore(value) {
    this.onlineCourseIsMCEnrolledBefore$.next(value);
  }

  clearCache() {
    this.onlineCourseCode$.next(null);
    this.onlineCourseOccur$.next(null);
    this.isResumingApplication$.next(false);
    this.onlineCourseType$.next(null);
    this.programme$.next(null);
    this.onlineCourseSelectedCourse$.next(null);
    this.onlineCourseIsFirstTimeToThisCourseType$.next(false);
    this.onlineCourseIsMCEnrolledBefore$.next(false);
    this.onlineCourseEnrolmentContextIsBuilt$.next(false);
  }

  getCourseYear() {
    return (
      this.onlineCourseSelectedCourse?.validFrom !== undefined
        ? new Date(this.onlineCourseSelectedCourse?.validFrom)
        : new Date()
    )
      .getFullYear()
      .toString();
  }

  isProd(): boolean {
    return environment.envName === ENV_NAMES.PROD;
  }

  isCourseValid(course: ReferenceData): boolean {
    const isTestCourse = course?.metadata?.testCourse === 'TRUE';
    const isProd = this.isProd();

    if (isProd && isTestCourse) {
      this.log.error('Test course found in production');
      return false;
    }

    const validDate = new Date(course?.validTo) > new Date();

    if (!validDate) {
      this.log.info('cannot enrol in course, current date after valid to date');
    }

    return validDate;
  }

  async getCourseInfoByQueryParams(queryParams) {
    this.onlineCourseCode = queryParams.enrol;
    this.onlineCourseOccur = queryParams.occur;
    this.isResumingApplication$.next(queryParams.resume && queryParams.resume === 'TRUE');

    const refData = await this.getRefData(this.onlineCourseCode, this.onlineCourseOccur);

    if (this.isCourseValid(refData)) {
      this.onlineCourseSelectedCourse = refData;
      this.onlineCourseType = this.courseCategoryToProcessName(refData?.metadata?.courseCategory.toString());
    }
  }

  async getEnrolledCourseTypes(existingApplicationYears: string[]): Promise<string[]> {
    // getAllEnrolmentList merges results to one value
    const enrolmentList = await firstValueFrom(this.enrolmentService.getAllEnrolmentList(existingApplicationYears));
    const enrolledCourseTypes = [];

    for (const enrolledCourse of enrolmentList) {
      const hasShortCourseEnrol = enrolledCourseTypes.includes(PROCESS_NAMES.UCONLINE_SHORT_COURSE);
      const hasMicroCredEnrol = enrolledCourseTypes.includes(PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL);
      // if enrolled to both course types no need to continue
      if (hasShortCourseEnrol && hasMicroCredEnrol) {
        break;
      }

      // enrolment only contains course code not course type so need to check ref data
      const refData = await this.getRefData(enrolledCourse.courseCode, enrolledCourse.occurrence);
      const process = this.courseCategoryToProcessName(refData?.metadata?.courseCategory.toString());

      if (!enrolledCourseTypes.includes(process)) {
        enrolledCourseTypes.push(process);
      }
    }

    return enrolledCourseTypes;
  }

  async getRefData(courseCode: string, occurrence: string): Promise<ReferenceData> {
    const res = await firstValueFrom(
      this.refDataService.getByCode(REFDATA_TYPES.UCONLINE_COURSE, courseCode + occurrence),
    );
    return ReferenceData.deserialize(res);
  }

  async isFirstTimeToThisCourseType(onlineCourseType: string, existingApplicationYears: string[]): Promise<boolean> {
    const enrolledCourseTypes = await this.getEnrolledCourseTypes(existingApplicationYears);
    return !enrolledCourseTypes.includes(onlineCourseType);
  }

  async buildOnlineCourseEnrolmentContext(queryParams) {
    this.log.info('>>> start of inside build context method');
    // Exist when context already built
    if (this.onlineCourseEnrolmentContextIsBuilt) {
      return;
    }

    const applicationSumms = await this.applicationService.getApplications().toPromise();
    const existingApplicationYears = applicationSumms.map((summary) => summary.academicYear);

    await this.getCourseInfoByQueryParams(queryParams);
    this.onlineCourseIsFirstTimeToThisCourseType = await this.isFirstTimeToThisCourseType(
      this.onlineCourseType,
      existingApplicationYears,
    );

    const enrolledCourseTypes = await this.getEnrolledCourseTypes(existingApplicationYears);
    // TODO: remove this (used in a few other places so havent removed it completly just refactored)
    this.onlineCourseIsMCEnrolledBefore = enrolledCourseTypes.includes(PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL);

    this.onlineCourseEnrolmentContextIsBuilt = true;

    this.log.info('>>> end of inside build context method');
  }

  courseCategoryToProcessName(courseCategory: string): string {
    if (!COURSE_CATEGORIES.includes(courseCategory)) {
      this.log.error(`Ivalid course category ${courseCategory}`);
    }

    // default to micro-cred for now
    return courseCategory === 'Short course'
      ? PROCESS_NAMES.UCONLINE_SHORT_COURSE
      : PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL;
  }
}

@Injectable()
export class MockOnlineCourseService {
  public onlineCourseType$ = new BehaviorSubject<string>(PROCESS_NAMES.UCONLINE_SHORT_COURSE);
  public onlineCourseCode$ = new BehaviorSubject<string>('519FX22031');
  public onlineCourseOccur$ = new BehaviorSubject<string>('1');
  public programme$ = new BehaviorSubject<string>('master');
  // TODO: make change according to the final online course reference data attributes
  public onlineCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(
    ReferenceData.deserialize({
      code: '519FX220311',
      description: 'Academic Writing Basics',
      type: 'uconline_course',
      valid_from: '2018-10-31',
      valid_to: '2023-01-26',
      metadata: {
        course_code: '519FX22031',
        course_name: 'Academic Writing Basics',
        course_category: 'Short course',
        course_occurrence: '1',
        course_tec_funded: 'FALSE',
        course_analysis_code: '51900:1980:519FX22031:SE303:1',
        course_domestic_price_gst: '100',
        course_domestic_price_excl_gst: '86.96',
        course_international_price_gst: '424.35',
        course_international_price_excl_gst: '369',
      },
    }),
  );
  public onlineTestCourseSelectedCourse$ = new BehaviorSubject<ReferenceData>(
    ReferenceData.deserialize({
      code: 'TESTSC01',
      description: 'Academic Writing Basics',
      type: 'uconline_course',
      valid_from: '2018-10-31',
      valid_to: '2030-11-11',
      metadata: {
        test_course: 'TRUE',
        course_code: 'TESTSC',
        course_name: 'first TEstcourse',
        course_category: 'Short course',
        course_occurrence: '01',
        course_tec_funded: 'FALSE',
        course_analysis_code: '51900:1980:519FX22031:SE303:1',
        course_domestic_price_gst: '100',
        course_domestic_price_excl_gst: '86.96',
        course_international_price_gst: '424.35',
        course_international_price_excl_gst: '369',
      },
    }),
  );
  public onlineCourseIsFirstTimeToThisCourseType$ = new BehaviorSubject<boolean>(false);
  public onlineCourseIsMCEnrolledBefore$ = new BehaviorSubject<boolean>(false);
  public onlineCourseEnrolmentContextIsBuilt$ = new BehaviorSubject<boolean>(false);

  get onlineCourseType(): string {
    return this.onlineCourseType$.value;
  }

  set onlineCourseType(value) {
    this.onlineCourseType$.next(value);
  }

  get onlineCourseCode(): string {
    return this.onlineCourseCode$.value;
  }

  set onlineCourseCode(value) {
    this.onlineCourseCode$.next(value);
  }

  get onlineCourseOccur(): string {
    return this.onlineCourseOccur$.value;
  }

  set onlineCourseOccur(value) {
    this.onlineCourseOccur$.next(value);
  }

  get programme(): string {
    return this.programme$.value;
  }

  set programme(value) {
    this.programme$.next(value);
  }

  get onlineCourseSelectedCourse(): ReferenceData {
    return this.onlineCourseSelectedCourse$.value;
  }

  set onlineCourseSelectedCourse(value) {
    this.onlineCourseSelectedCourse$.next(value);
  }

  get onlineCourseIsFirstTimeToThisCourseType(): boolean {
    return this.onlineCourseIsFirstTimeToThisCourseType$.value;
  }

  set onlineCourseIsFirstTimeToThisCourseType(value) {
    this.onlineCourseIsFirstTimeToThisCourseType$.next(value);
  }

  get onlineCourseIsMCEnrolledBefore(): boolean {
    return this.onlineCourseIsMCEnrolledBefore$.value;
  }

  set onlineCourseIsMCEnrolledBefore(value) {
    this.onlineCourseIsMCEnrolledBefore$.next(value);
  }

  get onlineCourseEnrolmentContextIsBuilt(): boolean {
    return this.onlineCourseEnrolmentContextIsBuilt$.value;
  }

  set onlineCourseEnrolmentContextIsBuilt(value) {
    this.onlineCourseEnrolmentContextIsBuilt$.next(value);
  }

  clearCache() {
    this.onlineCourseCode$.next(null);
    this.onlineCourseOccur$.next(null);
    this.onlineCourseType$.next(null);
    this.onlineCourseSelectedCourse$.next(null);
    this.onlineCourseIsFirstTimeToThisCourseType$.next(false);
    this.onlineCourseIsMCEnrolledBefore$.next(false);
    this.onlineCourseEnrolmentContextIsBuilt$.next(false);
  }

  courseCategoryToProcessName(courseCategory: string): string {
    return courseCategory === 'Short course'
      ? PROCESS_NAMES.UCONLINE_SHORT_COURSE
      : PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL;
  }

  getCourseYear() {
    return (new Date(this.onlineCourseSelectedCourse?.validFrom) || new Date()).getFullYear().toString();
  }

  isProd(): boolean {
    return environment.envName === ENV_NAMES.PROD;
  }

  isCourseValid(course: ReferenceData): boolean {
    const isTestCourse = course?.metadata?.testCourse === 'TRUE';
    const isProd = this.isProd();
    if (isProd && isTestCourse) {
      return false;
    }
    const validDate = new Date(course?.validTo) > new Date();
    return validDate;
  }

  // eslint-disable-next-line class-methods-use-this
  getCourseInfoByQueryParams(queryParams) {
    this.onlineCourseCode = queryParams?.enrol;
    this.onlineCourseOccur = queryParams?.occur;
    const refData = this.getRefData(queryParams);

    if (this.isCourseValid(refData)) {
      this.onlineCourseSelectedCourse = refData;
      this.onlineCourseType = this.courseCategoryToProcessName(refData?.metadata?.courseCategory.toString());
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  getEnrolledCourseTypes(existingApplicationYears: string[]) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  isFirstTimeToThisCourseType(existingApplicationYears: string[]) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
  getRefData(queryParams) {
    const refData = ReferenceData.deserialize(
      mockReferenceData()
        .uconline_course.filter((course) => course.code === queryParams?.enrol + queryParams?.occur)
        .pop(),
    );

    return refData;
  }

  buildOnlineCourseEnrolmentContext(queryParams) {
    const existingApplicationYears = [];
    this.getCourseInfoByQueryParams(queryParams);
    this.isFirstTimeToThisCourseType(existingApplicationYears);
  }
}
