import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

import { IndependentCourseEnrolmentSummary } from '@app/models/IndependentCourseEnrolmentSummary';
import { IndependentCourseEnrolmentService } from '@app/services/independent-course-enrolment/independent-course-enrolment.service';
import { ConfirmationModalComponent } from '@shared/components/atoms/confirmation-modal/confirmation-modal.component';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import strings from '@shared/constants/strings.constants';
import { ReferenceData } from '@shared/models/reference-data';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-independent-course-enrolment-summary-card',
  templateUrl: './independent-course-enrolment-summary-card.component.html',
  styleUrls: ['./independent-course-enrolment-summary-card.component.scss'],
})
export class IndependentCourseEnrolmentSummaryCardComponent implements OnInit {
  @Input() enrolment: IndependentCourseEnrolmentSummary;

  @ViewChild('withdrawConfirmationModal') confirmationModal: ConfirmationModalComponent;
  @ViewChild('errorModal') errorModal: ConfirmationModalComponent;

  @Output() enrolmentUpdated = new EventEmitter();

  courseName = '';
  actionUrl = '';
  actionUrlTitle = '';
  courseType = '';
  isTestCourse = false;
  enrolmentsClosedDate = '';
  strings;
  inProgress = false;
  process = '';

  errorTitle = '';
  errorMessage = '';

  modalTitle = '';
  modalMessage = '';

  // Unique ID for this component
  id = '';

  constructor(
    private refdata: ReferenceDataService,
    private courseService: IndependentCourseEnrolmentService,
  ) {
    this.strings = strings.components.molecules.independentCourseEnrolmentSummaryCard;
  }

  /**
   * Set the action URL for this enrolment
   *
   * @param title
   * @param url
   */
  setActionUrl(title, url) {
    this.actionUrl = url;
    this.actionUrlTitle = title;
  }

  /**
   * Set the course name from provided reference data
   *
   * @param referenceData
   * @param enrolment
   */
  setCourseName(course: ReferenceData) {
    if (course === null) {
      this.courseName = this.enrolment.courseCode;
    } else {
      this.courseName = course.metadata.courseName as string;
    }
  }

  /**
   * Set the course name from provided reference data
   *
   * @param referenceData
   * @param enrolment
   */
  setCourseType(course: ReferenceData) {
    if (course === null) {
      return '';
    }

    this.courseType = (course.metadata.courseCategory as string).toLocaleUpperCase();

    if (this.courseType === 'SHORT COURSE') {
      this.process = PROCESS_NAMES.UCONLINE_SHORT_COURSE;
    } else if (this.courseType === 'MICRO-CREDENTIAL') {
      this.process = PROCESS_NAMES.UCONLINE_MICRO_CREDENTIAL;
    }
  }

  /**
   * Set the course name from provided reference data
   *
   * @param referenceData
   * @param enrolment
   */
  setTestCourse(course: ReferenceData) {
    if (course === null) {
      return '';
    }

    this.isTestCourse = (course.metadata.test_course as string) === 'FALSE';
  }

  setCloseDate(course: ReferenceData) {
    const { validTo } = course;

    // Return early if there's no close date set
    if (!validTo || validTo === '') {
      return;
    }

    const parts = validTo.split('-');
    if (parts.length !== 3) {
      return;
    }

    const [year, month, day] = parts;
    this.enrolmentsClosedDate = `${day}/${month}/${year}`;
  }

  getReferenceDataCourse(referenceData: ReferenceData[], enrolment: IndependentCourseEnrolmentSummary) {
    const code = enrolment.courseCode + enrolment.occurrenceCode;
    const foundCourses = referenceData.filter((course) => course.code === code);

    if (foundCourses.length >= 1) {
      return foundCourses[0];
    }

    return null;
  }

  /**
   * Show an error modal
   *
   * @param message
   * @param title
   */
  showError(message: string, title: string) {
    this.errorMessage = message;
    this.errorTitle = title;

    this.errorModal.open();
  }

  /**
   * Cancel an independent course enrolment
   *
   * @returns
   */
  doEnrolmentCancel() {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;

    if (!this.process) {
      return;
    }

    this.courseService.cancelIndependentEnrolment(
      this.enrolment.academicYear,
      this.enrolment.courseCode,
      this.enrolment.occurrenceCode,
      this.id,
    );

    this.inProgress = false;
  }

  cancelEnrolment() {
    this.modalMessage = this.strings.cancelAreYouSure(this.courseName, this.process, this.enrolment.academicYear);
    this.confirmationModal.open();
  }

  /**
   * Subscriber for referencedata load
   */
  onReferenceData(referenceData) {
    const course = this.getReferenceDataCourse(referenceData, this.enrolment);

    if (course === null) {
      return;
    }

    this.setCourseName(course);
    this.setCourseType(course);
    this.setTestCourse(course);
    this.setCloseDate(course);
  }

  setAction() {
    if (this.enrolment.state === this.enrolment.STATE_ENROLLED) {
      return this.setActionUrl(this.strings.learnNow, this.strings.learnNowUrl);
    }

    if (this.enrolment.getApplicationStage() === this.enrolment.STAGE_PAYMENT) {
      return this.setActionUrl(this.strings.resume, this.enrolment.getResumeEnrolmentLink());
    }

    if (this.enrolment.isInActionableState()) {
      return this.setActionUrl(this.strings.resume, this.enrolment.getResumeEnrolmentLink());
    }

    return this.setActionUrl(this.strings.resume, this.enrolment.getResumeEnrolmentLink());
  }

  /**
   * Emit an enrolmentUpdated event to notify
   * parent that this enrolment has changed.
   */
  triggerReload() {
    this.enrolmentUpdated.emit(null);
  }

  ngOnInit(): void {
    this.refdata.getByType('uconline_course').subscribe(this.onReferenceData.bind(this));
    this.setAction();

    // Set an ID that's unique to this course
    this.id = this.enrolment.courseCode + this.enrolment.courseCode + Math.floor(Math.random() * 100);

    // Listen for withdrawal messages from the course service
    this.courseService.withdrawalMessages$.subscribe((message) => {
      if (!message) {
        return;
      }

      // If the message wasn't as a result of something this component did
      // ignore the message
      if (message.caller !== this.id) {
        return;
      }

      if (message.error) {
        this.errorTitle = message.title;
        this.errorMessage = message.message;

        if (this.errorModal) {
          this.errorModal.open();
        }
      }
    });
  }
}
