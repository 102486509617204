<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-online-header-bar
  [hasSideNav]="false"
  [hideNavItems]="true"
  [hideToolbarItems]="true"
  class="online-header-bar"></uc-online-header-bar>
<div class="page-container">
  <uc-online-stages-sidebar
    [isImpersonating]="isImpersonating"
    [firstIncompleteStageNumber]="firstIncompleteStageNumber"></uc-online-stages-sidebar>
  <uc-modal identifier="verificationSkipWarningModal" [closable]="true">
    <h1 class="modal-title">{{ onlineProcessPageStrings.skipVerificationModalTitle }}</h1>
    <div class="modal-body">
      {{ onlineProcessPageStrings.skipVerificationModalBodyFirst }}
    </div>
    <div class="modal-body" [innerHTML]="onlineProcessPageStrings.skipVerificationModalBodySecond">
    </div>
    <div class="modal-footer">
      <uc-online-button
        testSelector="skip-verification"
        [isLoading]="taskIsUpdating"
        class="online-ui-button"
        (click)="skipVerification()">
        {{ onlineProcessPageStrings.skipVerificationModalFooterButton }}
      </uc-online-button>
    </div>
  </uc-modal>
  <uc-modal identifier="cancelProcessWarningModal" [closable]="true">
    <h1 class="modal-title">{{ onlineProcessPageStrings.cancelProcessModalTitle }}</h1>
    <div class="modal-body">
      {{ onlineProcessPageStrings.cancelProcessModalBody }}
    </div>
    <div class="modal-footer-right">
      <button
        testSelector="cancel-process"
        [attr.data-cy]="'cancel-process'"
        [attr.data-testid]="'cancel-process'"
        class="cancel-link"
        (click)="cancelProcess()">
        {{ onlineProcessPageStrings.cancelProcessModalFooterButton }}
      </button>
      <uc-online-button
        testSelector="continue-process"
        [isLoading]="taskIsUpdating"
        [attr.data-testid]="'continue-process'"
        class="online-ui-button"
        (click)="continueProcess()">
        {{ onlineProcessPageStrings.continueProcessModalFooterButton }}
      </uc-online-button>
    </div>
  </uc-modal>
  <uc-modal identifier="validationWarningModal" [closable]="true">
    <h1 class="modal-title">{{ onlineProcessPageStrings.validationWarningModalTitle }}</h1>
    <div class="modal-body">
      <span>{{ onlineProcessPageStrings.validationWarningModalBody }}</span>
      <div *ngFor="let err of invalidSections" class="error">
        {{ err }}
      </div>
    </div>
    <div class="modal-footer">
      <uc-online-button
        testSelector="cancel-process"
        [isLoading]="taskIsUpdating"
        class="online-ui-button"
        (click)="closeValidationWarningModal()">
        {{ onlineProcessPageStrings.closeValidationWarningModalButton }}
      </uc-online-button>
    </div>
  </uc-modal>

  <div id="main-content" class="content" [ngSwitch]="task?.code">
    <div class="content-inner">
      <button
        [attr.data-cy]="'prev-task'"
        class="previous-task"
        (click)="previousTask()"
        [style.display]="isFirstTask ? 'none' : ''">
        <uc-icon iconType="back-arrow" iconWidth="17" iconHeight="17"></uc-icon>
        {{ strings.previousTask }}
      </button>
      <div *ngSwitchCase="'uconline-shortcourse-details'">
        <uc-online-shortcourse-details
          *ngIf="
            onlineCourseService.onlineCourseIsFirstTimeToThisCourseType &&
            !onlineCourseService.onlineCourseIsMCEnrolledBefore
          "
          [task]="task"
          [processName]="process.code"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-shortcourse-details>
        <uc-online-review-shortcourse-details
          *ngIf="
            !onlineCourseService.onlineCourseIsFirstTimeToThisCourseType &&
            !onlineCourseService.onlineCourseIsMCEnrolledBefore
          "
          [task]="task"
          [processName]="process.code"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-review-shortcourse-details>
        <uc-online-review-micro-credential-details
          *ngIf="onlineCourseService.onlineCourseIsMCEnrolledBefore"
          [task]="task"
          [processName]="process.code"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-review-micro-credential-details>
      </div>
      <div *ngSwitchCase="'uconline-microcredential-details'">
        <uc-online-micro-credential-details
          *ngIf="onlineCourseService.onlineCourseIsFirstTimeToThisCourseType"
          [task]="task"
          [processName]="process.code"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-micro-credential-details>
        <uc-online-review-micro-credential-details
          *ngIf="!onlineCourseService.onlineCourseIsFirstTimeToThisCourseType"
          [task]="task"
          [processName]="process.code"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-review-micro-credential-details>
      </div>
      <uc-online-study-location
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'uconline-study-location'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-study-location>
      <div *ngSwitchCase="'uconline-checkout'">
        <uc-online-payment-success
          *ngIf="paymentStatus === 'success'"
          [applicationYear]="applicationYear"
          [processName]="process.code"
          [task]="task"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-payment-success>
        <uc-online-payment-failed
          *ngIf="paymentStatus === 'cancel'"
          [applicationYear]="applicationYear"
          [processName]="process.code"
          [task]="task"
          #currentTask
          (next)="goToNextTask()"
          (errors)="showTaskError($event)"></uc-online-payment-failed>
      </div>
      <uc-online-additional-details
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'uconline-additional-details'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-additional-details>
      <uc-online-address-details
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'uconline-address-details'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-address-details>
      <uc-online-emergency-contact
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'uconline-emergency-contact'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-emergency-contact>
      <uc-online-study-history
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'uconline-microcredential-study-history'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-study-history>
      <uc-online-learner-declaration
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'uconline-learner-declaration'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-online-learner-declaration>
      <uc-online-verification
        [task]="task"
        #currentTask
        *ngSwitchCase="'uconline-verification'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-online-verification>
      <uc-online-juds-complete-stage
        [stageNumber]="stageNumber"
        [params]="params"
        [process]="process.code"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'uconline-complete-stage'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [isInternational]="process?.isInternational"></uc-online-juds-complete-stage>
      <uc-online-enrolment-success
        [stageNumber]="stageNumber"
        [params]="params"
        [process]="process.code"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'uconline-enrolment-success'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [isInternational]="process?.isInternational"></uc-online-enrolment-success>
      <uc-online-resolve-checkout-session
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-checkout-session'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        [courseCode]="courseCode"
        [occurrence]="occurrence"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-online-resolve-checkout-session>
      <uc-online-resolve-enrolment-provisioning
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-enrolment-provisioning'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        [courseCode]="courseCode"
        [occurrence]="occurrence"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-online-resolve-enrolment-provisioning>
      <uc-application-to-enrol
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'start-my-application'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-application-to-enrol>
      <uc-academic-history
        [applicationYear]="applicationYear"
        [task]="task"
        #currentTask
        *ngSwitchCase="'academic-history'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-academic-history>
      <uc-qualification
        [processFilter]="process.code"
        [task]="task"
        #currentTask
        *ngSwitchCase="'qualification'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-qualification>
      <uc-student-declaration
        [applicationYear]="applicationYear"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'declaration'"
        (next)="goToNextTask()"
        [isInternational]="process?.isInternational"
        [processComplete]="stageComplete"
        (errors)="showTaskError($event)"></uc-student-declaration>
      <uc-complete-stage
        [stageNumber]="stageNumber"
        [params]="params"
        [process]="process.code"
        [stage]="stage"
        [task]="task"
        #currentTask
        *ngSwitchCase="'complete-stage'"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"
        [isInternational]="process?.isInternational"></uc-complete-stage>
      <uc-finalise-enrolment
        [task]="task"
        [processName]="process.code"
        #currentTask
        *ngSwitchCase="'finalise'"
        [applicationYear]="applicationYear"
        (next)="goToNextTask()"
        (errors)="showTaskError($event)"></uc-finalise-enrolment>
      <uc-resolve-account
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-account'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-resolve-account>
      <uc-online-resolve-account
        [task]="task"
        #currentTask
        *ngSwitchCase="'resolve-account-uconline'"
        [process]="process.code"
        [applicationYear]="applicationYear"
        (next)="goToNextTask($event)"
        (errors)="showTaskError($event)"></uc-online-resolve-account>
      <p class="missing-task" *ngSwitchDefault>
        {{ missingTaskLabel }}
      </p>
      <div class="page-actions clearfix">
        <div class="errors">
          <uc-message-banner #errorBanner type="error">{{ errorMessage }}</uc-message-banner>
        </div>
        <div class="primary-links" *ngIf="!disableNavigation && !onlineSpecialAction">
          <button
            [attr.data-cy]="'cancel-task'"
            [attr.data-testid]="'cancel-task'"
            class="cancel-link"
            (click)="cancelTask()"
            *ngIf="!isVerification">
            {{ strings.cancelTask }}
          </button>
          <button [attr.data-cy]="'skip-task'" class="skip-link" (click)="skipTask()" *ngIf="isVerification">
            {{ onlineProcessPageStrings.skipTask }}
          </button>
          <uc-online-button
            testSelector="next-task"
            [isLoading]="taskIsUpdating"
            class="next-task online-ui-button"
            (click)="triggerTaskCheckAndUpdate()">
            {{ stageCompleteLabel }}
          </uc-online-button>
        </div>
        <div class="next-steps-links" *ngIf="!disableNavigation && onlineSpecialAction">
          <uc-online-button
            testSelector="next-task"
            [isLoading]="taskIsUpdating"
            class="next-task online-ui-button"
            (click)="triggerTaskCheckAndUpdate()"
            *ngIf="isCheckoutStage && paymentStatus === 'success'">
            {{ onlineProcessPageStrings.continueButton }}
          </uc-online-button>
          <button (click)="cancelTask()" *ngIf="isCheckoutStage && paymentStatus === 'cancel'">
            {{ strings.cancelTask }}
          </button>
          <uc-online-button
            testSelector="'try-again'"
            class="next-task online-ui-button"
            *ngIf="isCheckoutStage && paymentStatus === 'cancel'"
            (click)="retryCheckoutAgain()">
            {{ onlineProcessPageStrings.tryAgainButton }}
          </uc-online-button>
          <uc-online-button
            testSelector="to-uc-online-dashboard"
            class="next-task online-ui-button"
            *ngIf="isEnrolmentSuccess"
            (click)="toUCOnlineDashboard()">
            {{ continueToUCOnlineDashboardLabel }}
          </uc-online-button>
        </div>
      </div>
    </div>
    <uc-online-footer-minimal></uc-online-footer-minimal>
  </div>
</div>
<uc-tooltip-container></uc-tooltip-container>
