import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { OnlineProcessService } from '@app/services/online-process/online-process.service';
import strings from '@constants/strings.constants';
import { AbstractOnlineBaseTask } from '@shared/classes/abstract-online-base-task';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { Applicant } from '@shared/models/applicant';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { UCError } from '@shared/models/errors';
import { Name } from '@shared/models/name';
import { Task } from '@shared/models/task';
import { ApplicantService } from '@shared/services/applicant/applicant.service';
import { ApplicationService } from '@shared/services/application/application.service';
import { DSHttpError } from '@shared/services/data-service';
import { group, control, UCElementGroup } from '@shared/services/form-model-mapper/form';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-online-study-location',
  templateUrl: './online-study-location.component.html',
  styleUrls: ['./online-study-location.component.scss'],
})
export class OnlineStudyLocationComponent extends AbstractOnlineBaseTask implements OnInit {
  @Input() task: Task;
  @Input() applicationYear: string;
  @Input() processName: string;

  onlineStudyLocationPage: UntypedFormGroup;
  onlineStudyLocationForm: UCElementGroup;
  currentApplicant: Applicant;
  currentApplication: Application;
  dateOfBirthYear: number;
  isDisabled: boolean;
  isSameProcessExistInPreviousApplication: boolean;

  legalName: Name;
  name: Name[];

  yourDetailsTaskString = strings.components.tasks.onlineStudyLocation.yourDetails;
  termsAndConditionsStrings = strings.components.molecules.onlineTermsAndConditions;
  strings = strings.components.tasks.onlineStudyLocation;

  radioOptions = [
    {
      labelText: 'Yes',
      value: true,
    },
    {
      labelText: 'No',
      value: false,
    },
  ];

  processRequiringId = [PROCESS_NAMES.STAR, PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL];

  private log: Logger;

  constructor(
    private formModel: FormModelMapperService,
    private applicantService: ApplicantService,
    private applicationService: ApplicationService,
    private onlineProcessService: OnlineProcessService,
    private onlineCourseService: OnlineCourseService,
    logger: LoggingService,
  ) {
    super();
    this.log = logger.createLogger(this);
  }

  get isStarProcess(): boolean {
    return this.processName === PROCESS_NAMES.STAR;
  }

  get isMCEDProcess(): boolean {
    return [PROCESS_NAMES.MCED, PROCESS_NAMES.MICRO_CREDENTIAL].includes(this.processName);
  }

  get citizenship() {
    return this.onlineStudyLocationForm.controls.citizenship.controls;
  }

  get isNZCitizen(): boolean {
    return this.citizenship.citizenCategory?.control?.value?.code === 'NZCZ';
  }

  get isInternational(): boolean {
    return this.citizenship.citizenCategory?.control?.value?.code === 'OTHER';
  }

  ngOnInit() {
    this.onlineStudyLocationForm = this.createForm();
    this.onlineStudyLocationPage = this.onlineStudyLocationForm.asControl() as UntypedFormGroup;

    combineLatest(
      this.applicantService.getApplicant(),
      this.applicationService.application,
      this.applicationService.applicationEnrolments,
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(this.subscribeHandler);
  }

  // eslint-disable-next-line max-lines-per-function, class-methods-use-this
  private createForm(): UCElementGroup {
    return group({
      citizenship: group({
        studyInNz: control({ model: 'application', path: '/studyInNz', validators: [Validators.required] }),
      }),
      termsAndConditionsGroup: group({
        acceptedTermsAndPrivacy: control({
          model: 'application',
          path: '/acceptedTermsAndPrivacy',
          validators: [Validators.requiredTrue],
        }),
        declaredMetRequirements: control({
          model: 'application',
          path: '/declaredMetRequirements',
          validators: [Validators.requiredTrue],
        }),
        acceptedProvideIdentity: control({
          model: 'application',
          path: '/acceptedProvideIdentity',
          validators: [Validators.requiredTrue],
        }),
        uconlineMarketingEmails: control({
          model: 'applicant',
          path: '/uconlineMarketingEmails',
        }),
      }),
    });
  }

  private subscribeHandler = ([applicant, application, applicationEnrolments]: [
    Applicant,
    Application,
    ApplicationEnrolment[],
  ]) => {
    this.currentApplicant = applicant;
    this.currentApplication = application;
    this.log.info(applicationEnrolments);

    if (this.task?.percentComplete === 100) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }

    this.formModel.updateFormFromModel(this.onlineStudyLocationForm, applicant, application);
  };

  public updateFormValidity(err: UCError) {
    this.formModel.updateFormValidity(err.data, this.onlineStudyLocationForm);
  }

  public checkFormValidity(): string[] {
    this.onlineStudyLocationPage.updateValueAndValidity();
    if (this.onlineStudyLocationPage.invalid) {
      return this.formModel.getInvalidPathesInElementGroup(this.onlineStudyLocationForm);
    } else {
      return [];
    }
  }

  submitEnrolmentsIfRequired(): Observable<unknown> {
    return this.onlineProcessService.submitEnrolment(this.onlineCourseService.getCourseYear());
  }

  submitStageAndApplicationIfRequired(): Observable<unknown> {
    return this.onlineProcessService
      .submitStage(this.onlineCourseService.onlineCourseType, this.onlineCourseService.getCourseYear(), 'to-enrol')
      .pipe(switchMap(() => this.applicationService.getApplication(this.onlineCourseService.getCourseYear())));
  }

  public update() {
    if (!this.currentApplicant) {
      this.errors.emit();
      return this.log.error('tried to update applicant, but no applicant has been loaded yet');
    }

    this.formModel.updateModelFromForm(this.onlineStudyLocationForm, this.currentApplicant, this.currentApplication);
    this.currentApplicant.name = this.currentApplicant.name.filter((n) => !!n);
    const updateApplicant = this.applicantService.updateApplicant(this.currentApplicant);
    const updateApplication = this.applicationService.updateApplication(this.currentApplication);

    combineLatest(updateApplicant, updateApplication).subscribe({
      next: this.onUpdateSuccess,
      error: this.onUpdateFailed,
    });
  }

  private onUpdateSuccess = ([applicant, application]: [Applicant, Application]) => {
    if (applicant && application) {
      this.log.info('updated applicant and application successfully');
      this.next.emit();
    }
    this.submitStageAndApplicationIfRequired();
  };

  private onUpdateFailed = (err: DSHttpError) => {
    this.errors.emit();
    this.log.error('error thrown while updating applica(nt|tion):', err);
  };
}
