import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { throwError, of, EMPTY, firstValueFrom, from, Observable } from 'rxjs';
import { filter, switchMap, catchError, tap, map } from 'rxjs/operators';

import { OnlineCourseService } from '@app/services/online-course/online-course.service';
import { IApplicationProcess, OnlineProcessService } from '@app/services/online-process/online-process.service';
import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { PROCESS_NAMES, STAGE_NAMES } from '@shared/constants/app-names.constants';
import { PAYMENT_STATES } from '@shared/constants/states.constants';
import { Application } from '@shared/models/application';
import { ChangeOfEnrolment } from '@shared/models/change-of-enrolment';
import { IndependentCourseEnrolment } from '@shared/models/enrolment';
import { UCProcess } from '@shared/models/process';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { Logger, LoggingService } from '@shared/services/logging/logging.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';
import { UserService } from '@shared/services/user/user.service';

export interface IProcessRouteData {
  processContext: IProcessRouteContext;
}

/**
 * Represents the status of a UCProcess
 */
interface ProcessStatus {
  hasCompletedStages: boolean;
  hasCompletedToEnrol: boolean;
  hasCompletedCheckout: boolean;
  canSubmitCheckout: boolean;
  hasCompletedNonCheckoutStages: boolean;
}

/**
 * The object resolved by the ProcessResolver
 */
export interface IProcessRouteContext {
  process: UCProcess;
  application: Application | ChangeOfEnrolment;
}

/**
 * The parameters supplied to the Process resolver/page from the url config
 */
export interface IProcessRouteParams {
  process: string;
  year: string;
  stage: string;
  task: string;
}

@Injectable()
export class OnlineProcessResolver {
  public queryParams: unknown;
  public fragment: unknown;
  public isImpersonated = false;
  private lastLocation = undefined;
  private forceProcessUpdate = true;

  log: Logger;

  constructor(
    private router: Router,
    private onlineProcessService: OnlineProcessService,
    private flashMessageService: FlashMessageService,
    private loggingService: LoggingService,
    private referenceDataService: ReferenceDataService,
    private userService: UserService,
    private onlineCourseService: OnlineCourseService,
    private enrolmentService: EnrolmentService,
  ) {
    this.log = this.loggingService.createLogger(this);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.lastLocation = event.urlAfterRedirects;
    });

    this.userService.userDetail.subscribe((detail) => {
      this.isImpersonated = detail && !!detail.impersonated;
    });
  }

  get navigatedFromOutsideProcess(): boolean {
    return !this.lastLocation || !this.lastLocation?.match(/^\/apply/i);
  }

  /**
   * Calculte the status of a given process based on its completed stages.
   *
   * This function evaluates various conditions related to the process's stages
   * and returns an object indicating which stages have been completed and whether
   * the checkout stage can be submitted.
   *
   * @param {UCProcess} process - The process whose status needs to be determined.
   * @returns {ProcessStatus} - An object containing boolean flags for different
   *                            process stage completions and submission eligibility.
   */
  getProcessStatus(process: UCProcess): ProcessStatus {
    return {
      hasCompletedStages: process.stages.length > 1,
      hasCompletedToEnrol: this.hasCompletedStage(process, 'to-enrol'),
      hasCompletedCheckout: this.hasCompletedStage(process, 'checkout'),
      canSubmitCheckout: this.canSubmitStage(process, 'checkout'),
      hasCompletedNonCheckoutStages: this.hasCompletedNonCheckoutStages(process),
    };
  }

  /**
   * Determines whether a given process requires tidying up.
   *
   * This function checks the process's status and returns `true` if the process
   * has completed all necessary stages except checkout, and if the checkout stage
   * can still be submitted.
   *
   * @param {UCProcess} process - The process to evaluate.
   * @returns {boolean} - `true` if the process should be tidied up, `false` otherwise.
   */
  shouldTidyProcess(process: UCProcess): boolean {
    const status = this.getProcessStatus(process);

    return (
      status.hasCompletedStages &&
      status.hasCompletedToEnrol &&
      status.hasCompletedNonCheckoutStages &&
      !status.hasCompletedCheckout &&
      status.canSubmitCheckout
    );
  }

  /**
   * Checks if a stage can be submitted
   *
   * @param process - the process to check
   * @param stageCode - the stage to check
   * @returns - boolean indicating whether the stage can be submitted
   */
  canSubmitStage(process: UCProcess, stageCode: string): boolean {
    return !!process.stages.find(
      (stage) => stage.code === stageCode && stage.percentComplete === 100 && !stage.submitted,
    );
  }

  /**
   * Checks if a stage has been completed
   *
   * @param process - the process to check
   * @param stageCode - the stage to check
   * @returns - boolean indicating whether the stage has been completed
   */
  hasCompletedStage(process: UCProcess, stageCode: string): boolean {
    return !!process.stages.find((stage) => stage.code === stageCode && stage.submitted);
  }

  /**
   * Checks if all non-checkout stages have been completed.
   *
   * A stage is considered completed if it has been submitted.
   *
   * @param process - the process to check
   */
  hasCompletedNonCheckoutStages(process: UCProcess): boolean {
    const stages = process.stages.filter((stage) => stage.code !== 'checkout');
    return stages.every((stage) => stage.submitted);
  }

  /**
   * Fetch independent enrolment for the current application year / code / occurrence.
   *
   * Note: This will need to be replaced with something that uses the internal reference
   * in the future - but we're not set up for that now
   *
   * @returns - The independent enrolment & the year
   * @throws - Error if the enrolment cannot be fetched
   */
  async fetchIndependentEnrolment(): Promise<{ independentEnrolment: IndependentCourseEnrolment; year: string }> {
    const year = this.onlineCourseService.getCourseYear();
    const course = this.onlineCourseService.onlineCourseCode;
    const occur = this.onlineCourseService.onlineCourseOccur;

    const independentEnrolment = await firstValueFrom(
      this.enrolmentService.getIndependentEnrolment(year, course, occur),
    );

    return { independentEnrolment, year };
  }

  /**
   * Attempts to tidy up an incomplete process if necessary.
   *
   * This function checks whether the given process needs to be tidied up. If so,
   * it retrieves the independent enrolment data and verifies if the process is in
   * a 'created' state. If the process meets the criteria, it submits the 'checkout'
   * stage to finalize the cleanup.
   *
   * @param {UCProcess} process - The process to check and potentially tidy up.
   * @returns {Observable<boolean>} - Emits `true` if the process was tidied up,
   *                                  `false` otherwise (including if an error occurs).
   */
  tidyUpExistingProcesses(process: UCProcess): Observable<boolean> {
    if (!this.shouldTidyProcess(process)) {
      return of(false);
    }

    return from(this.fetchIndependentEnrolment()).pipe(
      switchMap(({ independentEnrolment, year }) => {
        if (independentEnrolment.state.code !== 'created') {
          return of(false);
        }

        this.log.warn('UI found an incomplete evaluation but has tidied it up');
        return this.onlineProcessService.submitStage(process.code, year, 'checkout').pipe(map(() => true));
      }),
      catchError((err) => {
        this.log.error(`Error in tidyUpExistingProcesses for process ${process.code}`, err);
        return of(false);
      }),
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProcessRouteContext> {
    this.log.info(state?.url);
    const { process: processCode, year, stage: stageCode, task: taskCode } = route.params as IProcessRouteParams;
    this.cacheQueryParamsAndFragment(route);
    this.onlineCourseService.buildOnlineCourseEnrolmentContext(this.queryParams);

    const preCheckResult = this.resolvePreCheck(processCode, year);
    if (preCheckResult === EMPTY) {
      return preCheckResult;
    }

    const forceUpdate = this.navigatedFromOutsideProcess && this.forceProcessUpdate;

    return this.referenceDataService.getByType('application_year').pipe(
      // Fetch process and application details using the retrieved reference data
      switchMap((referenceData) =>
        this.getProcessAndApplication(referenceData, year, processCode, forceUpdate).pipe(
          map(({ process, application }) => ({ process, application, referenceData })), // Preserve referenceData
        ),
      ),
      // Check if the process needs tidying and refresh if necessary
      switchMap(({ process, application, referenceData }) =>
        this.tidyUpExistingProcesses(process).pipe(
          switchMap((wasTidied) => {
            return wasTidied
              ? this.getProcessAndApplication(referenceData, year, processCode, true) // Reload process if tidied
              : of({ process, application });
          }),
        ),
      ),
      // Validate the process path and redirect if needed
      switchMap(({ process, application }: IApplicationProcess) => {
        return this.validatePathAndRedirect(process, application, year, stageCode, taskCode);
      }),
      // Handle any errors that occur during resolution
      catchError((err) => {
        return this.errorHandler(err);
      }),
    );
  }

  private cacheQueryParamsAndFragment(route: ActivatedRouteSnapshot) {
    this.queryParams = route.queryParams;
    this.fragment = route.fragment;
  }

  private resolvePreCheck(processCode, year) {
    let preCheckResult;
    preCheckResult = this.processCodeAndYearCheck(processCode, year);
    preCheckResult = this.isYearNaNCheck(year);

    return preCheckResult;
  }

  private processCodeAndYearCheck(processCode, year) {
    if (!(processCode && year)) {
      this.router.navigate(internalUrls.onlineApply, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return undefined;
    }
  }

  private isYearNaNCheck(year) {
    if (isNaN(parseInt(year, 10))) {
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return undefined;
    }
  }

  private getProcessAndApplication(referenceData, year, processCode, forceUpdate) {
    const validYears = referenceData.map((refData) => refData.code);
    if (!validYears.find((validYear) => validYear === year || Number(validYear) - 1 === Number(year))) {
      this.flashMessageService.pushError(strings.errors.invalidApplicationYear);
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    }
    if (processCode === PROCESS_NAMES.COE) {
      return this.onlineProcessService.fetchCOEProcess(processCode, year, forceUpdate).pipe(
        tap(() => {
          this.onlineProcessService.fetchCurrentApplicationProcess(processCode, year, forceUpdate).subscribe();
        }),
      );
    } else {
      return this.onlineProcessService.fetchCurrentApplicationProcess(processCode, year, forceUpdate);
    }
  }

  validatePathAndRedirect(process, application, year, stageCode, taskCode) {
    const redirectPath = this.validatePath(process, year, stageCode, taskCode);
    this.log.info(redirectPath);
    if (redirectPath) {
      this.forceProcessUpdate = false;
      this.log.info('Redirecting to', redirectPath.join('/'));
      this.router.navigate(redirectPath, { queryParams: this.queryParams });
      return EMPTY;
    }
    this.forceProcessUpdate = true;
    this.onlineProcessService.process$.next(process);
    return of({ process, application });
  }

  private errorHandler(err) {
    if (err.status === 404) {
      this.router.navigate(internalUrls.onlineApply, { queryParams: this.queryParams });
      return EMPTY;
    } else if (err.status === 409) {
      this.router.navigate(internalUrls.dashboard, { queryParams: this.queryParams });
      return EMPTY;
    } else {
      return throwError(err);
    }
  }

  // eslint-disable-next-line complexity
  validatePath(process: UCProcess, year: string, stageCode: string, taskCode: string): string[] | null {
    if (!process) {
      return internalUrls.onlineApply;
    }

    const stage = process.getStage(stageCode);
    const stageCheckResult = this.stageCheck(process, stageCode, year, stage, taskCode);
    if (!stageCheckResult.isFurtherCheckNeeded) {
      return stageCheckResult.url;
    }

    const taskCheckResult = this.taskCheck(process, stage, taskCode, year);
    if (!taskCheckResult.isFurtherCheckNeeded) {
      return taskCheckResult.url;
    }

    return null;
  }

  private checkOnDefaultStageIfHave(process: UCProcess, year: string) {
    let defaultStage = process.firstIncompleteStage || process.stages[0];
    if (this.onlineCourseService.onlineCourseIsFirstTimeToThisCourseType) {
      defaultStage = process.stages[0];
    }
    return internalUrls.onlineProcessPageTask(process.code, year, defaultStage.code, defaultStage.tasks[0].code);
  }

  private stageCheck(process, stageCode, year, stage, taskCode) {
    let stageCheckResult = { isFurtherCheckNeeded: true, url: undefined };

    stageCheckResult = this.whenStageExist(stageCode, process, year, taskCode);
    if (!stageCheckResult.isFurtherCheckNeeded) {
      return stageCheckResult;
    }

    if (!stage) {
      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = internalUrls.fourOhFour;
      return stageCheckResult;
    }

    return stageCheckResult;
  }

  private whenStageExist(stageCode, process, year, taskCode) {
    const stageCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (!stageCode) {
      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = this.checkOnDefaultStageIfHave(process, year);
      return stageCheckResult;
    }
    if (
      stageCode === STAGE_NAMES.CHECKOUT &&
      !['uconline-complete-stage', 'uconline-enrolment-success'].includes(taskCode)
    ) {
      this.checkoutStageValidate(
        process,
        year,
        this.onlineCourseService.onlineCourseCode,
        this.onlineCourseService.onlineCourseOccur,
      );

      stageCheckResult.isFurtherCheckNeeded = false;
      stageCheckResult.url = null;
      return stageCheckResult;
    }
    return stageCheckResult;
  }

  private taskCheck(process, stage, taskCode, year) {
    let taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };

    taskCheckResult = this.whenNotTaskCode(process, stage, taskCode, year);
    if (!taskCheckResult.isFurtherCheckNeeded) {
      return taskCheckResult;
    }

    const task = stage.getTask(taskCode);
    if (!task) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.fourOhFour;
      return taskCheckResult;
    }

    taskCheckResult = this.whenAutoResolveAndTaskCompleted(process, stage, task, taskCode, year);

    return taskCheckResult;
  }

  private whenNotTaskCode(process, stage, taskCode, year) {
    this.log.info(`Task code ${taskCode}`);
    const taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (!taskCode) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.onlineProcessPageTask(process.code, year, stage.code, stage.tasks[0].code);
      return taskCheckResult;
    }
    return taskCheckResult;
  }

  private whenAutoResolveAndTaskCompleted(process, stage, task, taskCode, year) {
    let taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    if (this.isAutoResolveAndTaskComplete(task)) {
      taskCheckResult = this.whenHasNextTask(process, stage, task, taskCode, year);
      if (!taskCheckResult.isFurtherCheckNeeded) {
        return taskCheckResult;
      } else {
        this.flashMessageService.pushInfo('This application is complete!');
        taskCheckResult.isFurtherCheckNeeded = false;
        taskCheckResult.url = internalUrls.dashboard;
        return taskCheckResult;
      }
    }
    return taskCheckResult;
  }

  private isAutoResolveAndTaskComplete(task) {
    this.log.info(task);
    return task.autoResolve && task.percentComplete === 100;
  }

  private whenHasNextTask(process, stage, task, taskCode, year) {
    this.log.info(stage);
    const taskCheckResult = { isFurtherCheckNeeded: true, url: undefined };
    const autoResolveIndex = stage.tasks.map((x) => x.code).indexOf(taskCode);
    const nextTask = stage.tasks[autoResolveIndex + 1] || null;
    if (nextTask) {
      taskCheckResult.isFurtherCheckNeeded = false;
      taskCheckResult.url = internalUrls.onlineProcessPageTask(process.code, year, stage.code, nextTask.code);
      return taskCheckResult;
    }
    return taskCheckResult;
  }

  async checkoutStageValidate(process: UCProcess, academicYear: string, courseCode: string, occurrence: string) {
    const targetIndependentEnrolment = await firstValueFrom(
      this.enrolmentService.getIndependentEnrolment(academicYear, courseCode, occurrence),
    );

    if (this.fragment === 'cancel') {
      this.cancelStripePaymentIfShould(process, academicYear);
    } else {
      this.redirectToStripePaymentIfShould(targetIndependentEnrolment, process, academicYear);
      this.navigateToPaymentResultIfShould(targetIndependentEnrolment, process, academicYear);
    }

    return EMPTY;
  }

  private cancelStripePaymentIfShould(process: UCProcess, academicYear: string) {
    const checkoutStageIndex = process.stages.findIndex((stage) => stage.code === STAGE_NAMES.CHECKOUT);
    const stageBeforeCheckout = process.stages[checkoutStageIndex - 1];
    const tasksOfStageBeforeCheckout = stageBeforeCheckout.tasks;
    const lastTaskOfStageBeforeCheckout = tasksOfStageBeforeCheckout[tasksOfStageBeforeCheckout.length - 1];

    const redirectPath = internalUrls.onlineProcessPageTask(
      process.code,
      academicYear,
      stageBeforeCheckout.code,
      lastTaskOfStageBeforeCheckout.code,
    );
    this.router.navigate(redirectPath, { queryParams: this.queryParams });
  }

  private redirectToStripePaymentIfShould(
    targetIndependentEnrolment: IndependentCourseEnrolment,
    process: UCProcess,
    academicYear: string,
  ) {
    if (
      targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_NONE ||
      targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_WAITING
    ) {
      const redirectPath = internalUrls.onlineProcessPageTask(
        process.code,
        academicYear,
        STAGE_NAMES.CHECKOUT,
        'resolve-checkout-session',
      );

      this.router.navigate(redirectPath, { queryParams: this.queryParams });
    }
  }

  private navigateToPaymentResultIfShould(
    targetIndependentEnrolment: IndependentCourseEnrolment,
    process: UCProcess,
    academicYear: string,
  ) {
    if (
      [PAYMENT_STATES.PAYMENT_STATE_DECLINED, PAYMENT_STATES.PAYMENT_STATE_PAID].includes(
        targetIndependentEnrolment.paymentState.code,
      )
    ) {
      const redirectPath = internalUrls.onlineProcessPageTask(
        process.code,
        academicYear,
        STAGE_NAMES.CHECKOUT,
        'uconline-checkout',
      );

      this.appendFragmentAndNavigate(targetIndependentEnrolment, redirectPath);
    }
  }

  private appendFragmentAndNavigate(targetIndependentEnrolment: IndependentCourseEnrolment, redirectPath: string[]) {
    if (targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_DECLINED) {
      this.router.navigate(redirectPath, { queryParams: this.queryParams, fragment: 'cancel' });
    }
    if (targetIndependentEnrolment.paymentState.code === PAYMENT_STATES.PAYMENT_STATE_PAID) {
      this.router.navigate(redirectPath, { queryParams: this.queryParams, fragment: 'success' });
    }
  }
}
