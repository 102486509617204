import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { IndependentCourseEnrolmentSummary } from '@app/models/IndependentCourseEnrolmentSummary';
import { environment } from '@environment';
import strings from '@shared/constants/strings.constants';
import { UCError } from '@shared/models/errors';
import { DSHttpError, DataService } from '@shared/services/data-service';

@Injectable({
  providedIn: 'root',
})
export class IndependentCourseEnrolmentService {
  public readonly enrolments$ = new Subject<IndependentCourseEnrolmentSummary[]>();
  private currentEnrolments: IndependentCourseEnrolmentSummary[] = [];
  private applicationLabelError$ = new Subject<UCError>();
  public readonly withdrawalMessages$ = new Subject<ICEWithdrawalMessage>();

  private strings = strings.services.independentCourseEnrolment;

  constructor(private dataService: DataService) {
    this.applicationLabelError$.subscribe(this.fetchErrorHandler);
  }

  /**
   * Fetch independent enrolments for this year, next year and the previous year
   *
   * @param year
   */
  fetchIndependentCourseEnrolmentSummary() {
    const currentYear = new Date().getFullYear();
    const yearsToFetch = [currentYear - 1, currentYear, currentYear + 1].map((year) => year.toString());

    this.currentEnrolments = [];

    yearsToFetch.forEach((year) => this.fetchIndependentCourseEnrolmentSummaryForYear.bind(this)(year));
  }

  /**
   * Fetch independent enrolments for a given year
   *
   * @param year
   */
  fetchIndependentCourseEnrolmentSummaryForYear(year: string) {
    const serviceUrl = `${environment.apiRoot}/enrolment`;
    const url = `${serviceUrl}/independent_enrolments/${year}`;

    this.dataService
      .fetch(url, {
        error$: this.applicationLabelError$,
        deserialize: (payload) => {
          const enrolments = payload.independent_enrolments.enrolled_courses;
          return enrolments.map((ice) => new IndependentCourseEnrolmentSummary({ ...ice, academic_year: year }));
        },
      })
      .subscribe((enrolments) => {
        this.currentEnrolments = [...this.currentEnrolments, ...enrolments];
        this.enrolments$.next(this.currentEnrolments);
      });
  }

  getEnrolments(year: string) {
    const serviceUrl = `${environment.apiRoot}/enrolment`;
    const url = `${serviceUrl}/independent_enrolments/${year}`;

    return this.dataService.fetch(url, {
      ignoredErrorStatuses: [404],
      deserialize: (payload) => {
        const enrolments = payload.independent_enrolments.enrolled_courses;
        return enrolments.map((ice) => new IndependentCourseEnrolmentSummary({ ...ice, academic_year: year }));
      },
    });
  }

  cancelIndependentCourseEnrolment(year: string, combinedCode: string, courseCode: string, occurrence: string) {
    const serviceUrl = `${environment.apiRoot}/enrolment`;
    const url = `${serviceUrl}/independent_enrolments/${year}?code=${combinedCode}&course_code=${courseCode}&occurrence=${occurrence}`;

    this.dataService.del(url, {
      expectStatus: 200,
    });
  }

  fetchProcesses(type: string, year: string) {
    const serviceUrl = `${environment.apiRoot}/process/${type}/${year}`;

    this.dataService
      .fetch(serviceUrl, {
        expectStatus: 200,
      })
      .subscribe((result) => {
        // eslint-disable-next-line no-console
        console.log(result);
      });
  }

  cancelIndependentEnrolment(year: string, courseCode: string, occurrence: string, caller: string) {
    const serviceUrl = `${environment.apiRoot}/enrolment/independent_enrolments/${year}?course_code=${courseCode}&occurrence=${occurrence}&code=${courseCode + occurrence}`;
    const withdrawErrors$ = new Subject<UCError | DSHttpError>();
    const withdrawSuccess$ = new Subject();

    this.dataService
      .del(serviceUrl, {
        error$: withdrawErrors$,
        success$: withdrawSuccess$,
        expectStatus: 200,
      })
      .subscribe((result) => {
        // eslint-disable-next-line no-console
        console.log(result);
      });

    withdrawErrors$.subscribe((error) => {
      if (error.code === 'errors.fourTwentyTwo' || error.code === 'errors.fourOhFour') {
        this.withdrawalMessages$.next({
          caller,
          shouldReload: true,
          error: true,
          title: this.strings.errorTitle,
          message: this.strings.errorMessage,
        });
      }
    });

    withdrawSuccess$.subscribe(() => {
      this.withdrawalMessages$.next({
        caller,
        shouldReload: true,
        error: true,
        title: this.strings.withdrawSuccessTitle,
        message: this.strings.withdrawSuccessMessage,
      });
    });
  }

  private fetchErrorHandler(error: UCError) {
    if (error.code === 'errors.fourOhFour') {
      // This is ok - means they didn't have an enrolment for the current year
      return;
    }

    // eslint-disable-next-line no-console
    console.error(error);
  }
}

@Injectable()
export class MockIndependentCourseEnrolmentService {
  public readonly enrolments$ = new BehaviorSubject<IndependentCourseEnrolmentSummary[]>([]);

  fetchIndependentCourseEnrolmentSummary() {
    this.enrolments$.next([
      new IndependentCourseEnrolmentSummary({
        state: { code: 'enrolled' },
        payment_state: { code: 'paid' },
        checkout_url: '',
        course_code: 'S2204',
        occurrence: '02',
      }),
    ]);
  }
}

export interface ICEWithdrawalMessage {
  error: boolean;
  message: string;
  title: string;
  shouldReload: boolean;
  caller: string;
}
